import Vue from 'vue'
import App from './App.vue'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { freeSet as icons } from '@coreui/icons'
import store from './store'
import DatabaseService from "./services/databaseservice"
import { Auth0Plugin } from "./auth"; //Import the Auth0 plugin here
import i18n from './i18n' //Import locales
import VueGtag from "vue-gtag";
import VueSocketIO from 'vue-socket.io'
import io from 'socket.io-client'

// Import the Auth0 configuration
const domain=process.env.VUE_APP_AUTH0_DOMAIN;
const clientId=process.env.VUE_APP_AUTH0_CLIENTID;
const audience=process.env.VUE_APP_AUTH0_AUDIENCE;
if (domain==undefined || domain==null) {
  console.trace("Error! VUE_APP_AUTH0_DOMAIN environment variable not defined");
}
if (clientId==undefined || clientId==null) {
  console.trace("Error! VUE_APP_AUTH0_CLIENTID environment variable not defined");
}
if (audience==undefined || audience==null) {
  console.trace("Error! VUE_APP_AUTH0_AUDIENCE environment variable not defined");
}

// Install the Auth0 authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

Vue.use(new VueSocketIO({
  debug: true,
  connection: io(process.env.VUE_APP_DEVELOPMENT_NODESERVER_BASEURL, {
    transports: ['websocket'],
    auth: {
      token: undefined,
    },
    autoConnect: false
  }),
  vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_'
  },
}))

const googleAnalyticsID=process.env.VUE_APP_GOOGLE_ANALYTICS_ID;
if (googleAnalyticsID==undefined || googleAnalyticsID==null) {
  console.trace("Error! VUE_APP_GOOGLE_ANALYTICS_ID environment variable not defined");
} else {
  //Install gtag for google analytics
  Vue.use(VueGtag, {
    config: { id: googleAnalyticsID }
  }, router);
}


//Reseting global states that needs to be reset on reload
store.dispatch("setSelectedUserKey",null);

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)

Vue.config.productionTip = false

Vue.prototype.$localeOptionsTime={hour:'2-digit', minute:'2-digit'}
Vue.prototype.$localeOptionsDate={year: 'numeric', month: 'short', day: 'numeric'}
Vue.prototype.$localeOptions={...Vue.prototype.$localeOptionsTime, ...Vue.prototype.$localeOptionsDate}
Vue.prototype.$convertDatabaseTimeToDisplayLocale=function (databaseTime) {
  const createTime=new Date(databaseTime);
  //return createTime.toLocaleTimeString(undefined,this.$localeOptionsTime)+","+createTime.toLocaleDateString(undefined,this.$localeOptionsDate);
  return createTime.toLocaleDateString(undefined,this.$localeOptionsDate)+" ("+createTime.toLocaleTimeString(undefined,this.$localeOptionsTime)+")";
}
Vue.prototype.$userIdleTime=Vue.observable({seconds:0})
Vue.prototype.$databaseService=new DatabaseService(Vue.prototype.$auth.getTokenSilently, store);
Vue.prototype.$errorModal=Vue.observable({text:null})

Vue.prototype.$readableDuration=function (seconds) {
  let h=Math.trunc(seconds/3600);
  let m=Math.trunc((seconds%3600)/60);
  let s=Math.trunc(seconds%60);
  return h.toString()+"h "+m.toString().padStart(2,"0")+"m "+s.toString().padStart(2,"0")+"s";
}

new Vue({
  router,
  icons,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')

