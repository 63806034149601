import axios from "axios";

export default axios.create({
	baseURL: "/api",
	headers: {
		"Content-type": "application/json"
	}
});

export const mobileApi = axios.create({
	baseURL: "/mobileapi",
	headers: {
		"Content-type": "application/json"
	}
});


